import { VariantProps } from 'class-variance-authority';
import React from 'react';
import { Label, LabelProps, Text, TextProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { fieldLabel } from '../../electrons/field';

export interface FieldProps {
  label?: string | React.ReactNode;
  description?: string;
  className?: string;
  errorMessage?: string | string[];
}

export const FieldError = React.forwardRef<HTMLElement, TextProps & { label?: string }>(
  ({ className, label, ...props }, ref) => (
    <Text
      aria-label={label}
      className={twMerge('text-negative-400 mt-1 inline-block text-xs', className)}
      ref={ref}
      role="alert"
      slot="errorMessage"
      {...props}
    />
  ),
);

export const FieldLabel = React.forwardRef<
  HTMLLabelElement,
  LabelProps & VariantProps<typeof fieldLabel>
>(({ className, weightLabel, fontSize, ...props }, ref) => (
  <Label
    className={twMerge(fieldLabel({ weightLabel, fontSize }), className)}
    ref={ref}
    {...props}
  />
));

export const FieldDescription = React.forwardRef<HTMLElement, TextProps>(
  ({ className, ...props }, ref) => (
    <Text
      className={twMerge('text-grey-600 mt-2 inline-block text-sm', className)}
      ref={ref}
      slot="description"
      {...props}
    />
  ),
);
